import logo from "../logo.svg";
import facebook from '../facebook.png'
import instagram from '../instagram.png'
function Header() {
    return (
        <header>
          <div>
            <section>
            <a
            className=""
            href="https://www.facebook.com/profile.php?id=61557133377005"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="c-icon c-icon--md"
              data-v-7725375b=""
              src={facebook}
              alt="Facebook Icon"
            />
          </a>
          <a
            className=""
            href="https://www.instagram.com/brushpetals/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="c-icon c-icon--md"
              data-v-7725375b=""
              src={instagram}
              alt="Instagram Icon"
            />
          </a>
            </section>
            <section>
              <h1>
                <img
                    src={logo}
                    className="App-logo"
                    alt="Brushpetals logo, dream your home"
                />
              </h1>
            </section>
          </div>
          <h2>Transforming your residential spaces into colorful sanctuaries</h2>
        </header>
    );
}

export default Header;