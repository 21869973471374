import "./App.css";
import Header from "./header/header";
import Footer from "./footer/footer";
import HomePage from "./homepage/homepage";

function App() {
  return (
    <>
      <Header/>
      <HomePage/>
      <Footer/>
    </>
  );
}

export default App;
