import contactUs from "../contact.svg";

function HomePage() {
  return (
    <div className="container">
        <h2>About Brushpetals</h2>
        <p>
          At Brushpetals, we're not just experts in exterior and interior
          painting; we're artists wielding brushes as our palettes, transforming
          houses into homes and spaces into sanctuaries.
        </p>
        <p>
          Our commitment goes beyond mere aesthetics. We understand that your
          home is your haven, your refuge from the world. That's why we approach
          every project with care, precision, and a keen eye for detail.
        </p>
        <p>
          Whether it's adding a fresh coat of paint to breathe new life into
          your walls or crafting a serene garden oasis where tranquility
          blossoms, we're here to make your vision flourish.
        </p>
        <p>
          With our expertise in landscaping and gardening, we don't just design
          outdoor spaces; we sculpt them into living canvases. From lush lawns
          to vibrant flower beds, our skilled hands mold nature's beauty to
          complement your home's architecture and reflect your personal style.
        </p>
        <p>
          At Brushpetals, we believe in the power of transformation. Let us
          transform your living space into a masterpiece that speaks volumes
          about who you are and what you cherish. Welcome home to Brushpetals,
          where every stroke of the brush and every petal in the garden is
          infused with passion and dedication.
        </p>
        <h2>Our Services</h2>
        <ul>
          <li>Exterior and Interior Painting</li>
          <li>Wallpaper removal, Plaster Repair, Drywall Patching</li>
          <li>Gardening: Mowing, Trimming, Planting, Fertilization</li>
          <li>Garden Maintenance Services</li>
        </ul>
        <h2>Contact Us</h2>
        <p>
          Ready to transform your living space into a masterpiece? Contact us
          today!
        </p>
        <a className="App-link" href='https://book.servicem8.com/request_booking?uuid=0b779cde-7782-43c6-9bfc-21658f51930b'>
          <img src={contactUs} alt='Contact us' />Contact us
        </a>
      </div>
  );
}

export default HomePage;